.introduction-jumbotron {
    text-align: center;
    padding-top: 10rem;
    padding-bottom: 5rem;
  }
  
.introduction-cover {
  background-size: cover;
  color: white;
  background-position: center center;
  position: relative;
  z-index: -1;
}

.overlay {
  background-color: #000;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}