.footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
}
.carousel-item > img {
  object-fit: cover;
}

.carousel-item > .overlay {
  background-color: #000;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.carousel-control-prev {
  z-index: 3;
}

.carousel-control-next {
  z-index: 3;
}

.home-detail-image {
  max-width: 500px;
  max-height: 400px;
}




.coach-image {
    border-radius: 10%;
    max-width: 250px;
}
.introduction-jumbotron {
    text-align: center;
    padding-top: 10rem;
    padding-bottom: 5rem;
  }
  
.introduction-cover {
  background-size: cover;
  color: white;
  background-position: center center;
  position: relative;
  z-index: -1;
}

.overlay {
  background-color: #000;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.contact-jumbotron {
  text-align: center;
  padding-top: 6rem;
  padding-bottom: 4rem;
}

.contact-cover {
  background-size: cover;
  color: white;
  background-position: center center;
  position: relative;
  z-index: -1;
}
.sessions-jumbotron {
    text-align: center;
    padding-top: 6rem;
    padding-bottom: 5rem;
  }
  
.sessions-cover {
  background-size: cover;
  color: white;
  background-position: center center;
  position: relative;
  z-index: -1;
}

html {
    height: 100%;
    box-sizing: border-box;
}
  
*, *:before, *:after {
    box-sizing: inherit;
}
  
body {
    position: relative;
    margin: 0;
    padding-bottom: 6rem;
    min-height: 100%;
    background-color:#f8f9fa ;
}

.bk-blue {
    color: #26c4ec ;
}

.bk-black {
    color: #000 ;
}

.bk-bg-blue {
    background-color: #26c4ec ;
}

.jumbotron > .overlay {
    background-color: #000;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -2;
}

.round-image {
    border-radius: 50%;
    max-width: 250px;
}

.shadow {
    box-shadow: 2px 2px 5px #ccc;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}
   
.react-player {
    position: absolute;
    top: 0;
    left: 0;
}
