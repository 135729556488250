html {
    height: 100%;
    box-sizing: border-box;
}
  
*, *:before, *:after {
    box-sizing: inherit;
}
  
body {
    position: relative;
    margin: 0;
    padding-bottom: 6rem;
    min-height: 100%;
    background-color:#f8f9fa ;
}

.bk-blue {
    color: #26c4ec ;
}

.bk-black {
    color: #000 ;
}

.bk-bg-blue {
    background-color: #26c4ec ;
}

.jumbotron > .overlay {
    background-color: #000;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -2;
}

.round-image {
    border-radius: 50%;
    max-width: 250px;
}

.shadow {
    box-shadow: 2px 2px 5px #ccc;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}
   
.react-player {
    position: absolute;
    top: 0;
    left: 0;
}