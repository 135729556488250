.contact-jumbotron {
  text-align: center;
  padding-top: 6rem;
  padding-bottom: 4rem;
}

.contact-cover {
  background-size: cover;
  color: white;
  background-position: center center;
  position: relative;
  z-index: -1;
}