.carousel-item > img {
  object-fit: cover;
}

.carousel-item > .overlay {
  background-color: #000;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.carousel-control-prev {
  z-index: 3;
}

.carousel-control-next {
  z-index: 3;
}

.home-detail-image {
  max-width: 500px;
  max-height: 400px;
}